import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import $ from 'jquery';

export default class extends Controller {
  static targets = ["matchid"]
  connect() {
    let self = this;
    this.contextMenu = this.ContextMenu({
      menus: [
        {
          name: "预测全场大小成功",
          onClick: function (e) {
            Rails.ajax({
              type: "post",
              dataType:'json',
              url: "/matches/" + self.matchidTarget.dataset.id + "/target",
              data: 'clazz=1&success=true',
              success: function(res) {
               const menus = self.contextMenu.getInstance();
                menus.classList.add("hidden");
              }
            })
          },
        },
        {
          name: "预测半场大小成功",
          onClick: function (e) {
            Rails.ajax({
              type: "post",
              dataType:'json',
              url: "/matches/" + self.matchidTarget.dataset.id + "/target",
              data: 'clazz=2&success=true',
              success: function(res) {
               const menus = self.contextMenu.getInstance();
                menus.classList.add("hidden");
              }
            })
          },
        },
        {
          name: "预测全场大小失败",
          onClick: function (e) {
            Rails.ajax({
              type: "post",
              url: "/matches/" + self.matchidTarget.dataset.id + "/target",
              data: 'clazz=1&success=false',
              success: function(res) {
               const menus = self.contextMenu.getInstance();
                menus.classList.add("hidden");
              }
            })
          },
        },
        {
          name: "预测半场大小失败",
          onClick: function (e) {
            Rails.ajax({
              type: "post",
              url: "/matches/" + self.matchidTarget.dataset.id + "/target",
              data: 'clazz=2&success=false',
              success: function(res) {
               const menus = self.contextMenu.getInstance();
                menus.classList.add("hidden");
              }
            })
          },
        },
      ],
    });
    document.addEventListener("contextmenu", showMenu);
    document.addEventListener("click", hideMenu);

    function showMenu(e) {
      e.preventDefault();
      const menus = self.contextMenu.getInstance();
      menus.style.top = `${e.clientY}px`;
      menus.style.left = `${e.clientX}px`;
      menus.classList.remove("hidden");
    }

    function hideMenu(event) {
      const menus = self.contextMenu.getInstance();
      menus.classList.add("hidden");
    }


  }

  disconnect() {
  }

  /**
 * options:
 * {
 *  menus:[{
 *    name: string,
 *    onClick: Function
 *  }],
 * }
 */
 ContextMenu (options) {
  let instance;

  function createMenu() {
    const ul = document.createElement("ul");
    ul.classList.add("custom-context-menu");
    const { menus } = options;
    if (menus && menus.length > 0) {
      for (let menu of menus) {
        const li = document.createElement("li");
        li.textContent = menu.name;
        li.onclick = menu.onClick;
        ul.appendChild(li);
      }
    }
    const body = document.querySelector("body");
    body.appendChild(ul);
    return ul;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createMenu();
      }
      return instance;
    },
  };
}




}
